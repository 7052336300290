// Import global vars
@import "master";
@import "fonts";

html,
body {
  height: 100%;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.main-content {
  flex: 1 0 auto;
}
.main-footer {
  flex-shrink: 0;
}

img {
  max-width: 100%;
}

p {
  font-size: 18px;
  font-weight: 300;
}

.lottie-player {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.delay-1 {
  animation-delay: 200ms;
}
.delay-2 {
  animation-delay: 400ms;
}
.delay-3 {
  animation-delay: 600ms;
}
.delay-4 {
  animation-delay: 800ms;
}
.delay-5 {
  animation-delay: 1000ms;
}

.speed-slow {
  animation-duration: 1200ms !important;
}
// .fadeInUp {
//   animation-duration: 1200ms !important;
// }

.text-big-1 {
  font-size: 20px;
}

.clean-a {
  text-decoration: none;
  color: inherit;
}

.main-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding-top: 30px;
  padding-bottom: 20px;

  .logo-dark {
    display: none;
  }
  .logo-regular {
    display: block;
  }
  .menu-icon-dark {
    display: none;
  }
  .menu-icon-regular {
    display: block;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);

    .logo-dark {
      display: block;
    }
    .logo-regular {
      display: none;
    }
    .menu-icon-dark {
      display: block;
    }
    .menu-icon-regular {
      display: none;
    }

    .main-header {
      &__menu {
        &-link {
          color: $primaryColor;
        }
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $primaryColor !important;
        }

        &.dd-button {
          border-color: $primaryColor !important;
          box-shadow: none;
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Signika Negative", sans-serif;

    display: flex;
    margin-left: -16px;
    margin-right: -16px;
    &-item {
      padding-left: 16px;
      padding-right: 16px;

      font-size: 20px;
    }
    &-link {
      color: $textAccentColor;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        color: $accentColor;
      }

      &.active {
        position: relative;
        color: $accentColor;
        &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $accentColor;
        }
      }
    }
  }

  &__burger-btn {
    background: none;
    border: none;
    outline: none;
  }
}

.main-header__mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primaryColor;
  overflow: auto;
  height: 100%;

  transition: 400ms;
  transform: translateX(-100%);
  &.active {
    transform: translateX(0%);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-left: 15px;
  }
  &-close {
    background: none;
    border: none;
    outline: none;
  }

  &-nav-wrap {
    padding: 30px 15px;
    overflow: auto;
  }
  &-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    &-item {
      font-size: 20px;
      margin-bottom: 15px;
    }
    &-link {
      color: $textAccentColor;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        color: $accentColor;
      }
      &.active {
        position: relative;
        color: $accentColor;
        &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $accentColor;
        }
      }
    }
  }
}

.main-footer {
  padding-top: 20px;
  padding-bottom: 60px;

  &__s-menu {
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    &-link {
      color: inherit;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        color: $primaryColor;
      }
    }
    &-icon {
      margin-right: 24px;
    }
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;

  &--extra-top {
    padding-top: 140px;
  }

  &--extra-bottom {
    padding-bottom: 140px;
  }

  &-title {
    font-size: 40px;
    font-weight: bold;
    font-family: "Signika Negative", sans-serif;
    color: $primaryColor;
  }
}

.section-hero {
  background: $primaryColor;
  min-height: 768px;
  padding-top: 80px;
  padding-bottom: 20px;

  &__title {
    font-family: "Signika Negative", sans-serif;
    font-size: 80px;
    font-weight: bold;
    color: $accentColor;
    margin-bottom: 15px;
    text-align: left;
  }
  &__subtitle {
    font-size: 30px;
    color: $textAccentColor;
    text-align: right;
    font-weight: 400;
  }

  &__splash-wrap {
    transform: translateY(60px);
  }
}

.section-hero-price {
  background: $primaryColor;
  padding-top: 120px;
  padding-bottom: 20px;
}

.hello-player-wrap {
  height: 460px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ty-card-wrap {
  padding-top: 50px;
  margin-bottom: 30px;
}
.ty-card {
  position: relative;
  box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
  padding: 24px 18px;
  border-radius: 15px;
  padding-top: 70px;

  &__number {
    font-size: 40px;
    font-weight: bold;
    background: $primaryColor;
    display: inline-flex;
    border-radius: 50%;
    padding: 10px 29px;
    color: $accentColor;
    border: 5px solid #fff;
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);

    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__icon-wrap {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: bold;
    font-family: "Signika Negative", sans-serif;
    text-align: center;
    color: $primaryColor;
  }
  &__content {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
}

.mobile-slick .slick-slide {
  padding: 10px 15px;
}

.ty-accordion {
  .accordion-item {
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 15px 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .accordion-button {
    color: $primaryColor;
    font-size: 20px;

    font-weight: bold;
    background: none;
    box-shadow: none;

    padding: 0;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FBB040'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
  }
  .accordion-body {
    padding: 0;
    padding-top: 20px;
    font-weight: 300;
    font-size: 18px;
  }
}

.hero-tabs {
}
.hero-tab {
  display: none;
  &.active {
    display: block;
  }
}
.hero-tabs-control {
  display: flex;
  justify-content: center;
  margin-left: -30px;
  margin-right: -30px;
}

.hero-tab-btn-wrap {
  padding: 0 30px;
}
.hero-tab-btn {
  font-size: 20px;
  font-family: "Signika Negative", sans-serif;
  color: $textAccentColor;

  text-decoration: none;
  transition: 200ms;
  &:hover {
    color: $accentColor;
  }

  &.active {
    color: $accentColor;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      height: 1px;
      width: 100%;
      background: $accentColor;
    }
  }
}

.hero-slider-controls-wrap {
  position: relative;
  width: 50%;
  margin: auto;
  padding-bottom: 30px;
}
.hero-slider-controls {
  position: relative;
  max-width: 800px;
  margin: auto;
  margin-left: 302px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  .slick-dots {
    position: static;
    bottom: unset;
    top: 0;

    li {
      margin: 0 7.5px;
    }
    li.slick-active button:before {
      color: $accentColor;
      opacity: 1;
    }
    li button:before {
      font-size: 20px;
      color: #fff;
      opacity: 1;
    }
  }
  .slick-arrow {
    &.slick-disabled {
      opacity: 0.25;
    }
    &::before {
      content: none;
    }
  }
}

.arrow-left {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36.168' height='28.777' viewBox='0 0 36.168 28.777'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='36.168' height='28.777' transform='translate(0 0)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 0)'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M14.733,28.777a.89.89,0,0,1-.622-.253L.267,15a.89.89,0,0,1,0-1.273L14.113.252a.888.888,0,1,1,1.239,1.273L2.161,14.366,15.354,27.253a.889.889,0,0,1-.621,1.524' fill='%23fff'/%3E%3Cpath d='M35.28,15.268H.889a.888.888,0,1,1,0-1.777H35.28a.888.888,0,0,1,0,1.777' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 36px;
  height: 29px;
}
.arrow-right {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36.168' height='28.777' viewBox='0 0 36.168 28.777'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='36.168' height='28.777' transform='translate(0 0)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 0)' clip-path='url(%23a)'%3E%3Cpath d='M21.436,28.777a.888.888,0,0,1-.62-1.525L34.007,14.41,20.815,1.524A.889.889,0,0,1,22.057.253L35.9,13.776a.89.89,0,0,1,0,1.272L22.056,28.525a.886.886,0,0,1-.62.252' fill='%23fff'/%3E%3Cpath d='M35.28,15.286H.889a.889.889,0,1,1,0-1.777H35.28a.889.889,0,0,1,0,1.777' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  width: 36px;
  height: 29px;
}

.hero-slider-wrap {
  position: relative;
  max-width: 800px;
  margin: auto;
  padding-top: 60px;
}
.hero-image-slider {
  position: absolute;
  z-index: 10;
  // transform: translateY(100px);
  &__image-wrap {
  }
  &__image {
    width: 242px;
    max-width: none;
  }

  &__slide {
    display: none;
    &.active {
      display: block;
    }
  }
}
.hero-slider {
  position: relative;
  &__inner {
    // display: flex;
    // max-width: 800px;
    // margin: auto;
    // padding-top: 100px;
    padding-left: 302px;
  }

  &__step-title {
    font-size: 40px;
    font-weight: 300;
    font-family: "Signika Negative", sans-serif;
    margin-bottom: 6px;
    color: #fff;
  }
  &__title {
    font-size: 50px;
    font-weight: 700;
    font-family: "Signika Negative", sans-serif;
    margin-bottom: 34px;
    color: $accentColor;
  }
  &__text {
    color: #fff;
    font-size: 20px;
    margin-bottom: 80px;
  }
}

.c-price {
  display: flex;
  color: $primaryColor;
  margin-bottom: 50px;

  &__content {
    // width: 66.6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 114px;
    font-family: "Signika Negative", sans-serif;
    line-height: 100px;
  }
  &__subtitle {
    font-size: 22px;
    color: $textColor;
  }

  &__value {
    // width: 33.3%;
    font-size: 260px;
    line-height: 192px;
    font-family: "Signika Negative", sans-serif;
  }
}
.price-title {
  font-family: "Signika Negative", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: $primaryColor;
  margin-bottom: 20px;
}
.price-text {
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 80px;
}

.kids-amount {
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  padding: 6px;

  &__input {
    border: none;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    -moz-appearance: textfield; /* Firefox */
  }

  &__btn {
    font-size: 60px;
    border-radius: 50%;
    color: $textAccentColor;
    background: $primaryColor;
    width: 44px;
    height: 44px;
    border: none;
    outline: none;
    font-weight: 300;

    display: flex;
    justify-content: center;
    align-items: center;
    span {
      // line-height: 44px;
    }
  }
}

.radio-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 45px;

  &__item {
    padding: 0 15px;
  }
}
.radio-set {
  /* The container */
  &__label {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 24px;
    font-weight: 500;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .radio-set__checkmark {
        background-color: $primaryColor;
      }
      &:checked ~ .radio-set__checkmark:after {
        display: block;
      }
    }

    &:hover input ~ .radio-set__checkmark {
      // background-color: #ccc;
    }
  }

  /* Create a custom radio button */
  &__checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: 200ms;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   display: none;

    //   top: 9px;
    //   left: 9px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: white;
    // }
  }
}

.price-card {
  padding: 20px 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  text-align: center;
  transform: translateY(-100px);
  background: #fff;

  &__title {
    font-size: 40px;
    font-family: "Signika Negative", sans-serif;
    font-weight: bold;
    color: $primaryColor;
    margin-bottom: 28px;
  }
  &__tile {
    border-bottom: 1px solid #707070;
    margin-bottom: 18px;
    &-title {
      font-size: 20px;
      margin-bottom: 14px;
    }
    &-value {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
  &__summary {
    margin-bottom: 20px;
    &-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    &-value {
      font-size: 50px;
      font-family: "Signika Negative", sans-serif;
      font-weight: bold;
      color: $primaryColor;
    }
  }
  &__note {
    font-size: 12px;
    color: #adadad;
    margin-bottom: 20px;
  }
  &__submit-wrap {
    margin-bottom: -40px;
  }
  &__submit {
    border: none;
    outline: none;

    padding: 4px 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: $primaryColor;
    border-radius: 33px;
    text-align: center;
    color: $textAccentColor;
    font-size: 30px;
    font-family: "Signika Negative", sans-serif;
    font-weight: bold;
    min-width: 270px;
  }
}

.mobile-price-footer {
  background: url("../assets/spike-bg.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-size: contain;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  display: none;
}
.mobile-price-footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  display: none;
}
